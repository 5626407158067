import { fetchCacheConverter } from 'utils/cloudflare';

import type { AlgoliaConfig, AlgoliaRecommendationHit } from 'types/algolia';
import type { AlgoliaProduct } from 'types/product';
import { REVALIDATE } from 'lib/constants-cache';

export async function getAlgoliaConfig(): Promise<AlgoliaConfig | undefined> {
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.oneDay_86400,
  });
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_ALGOLIA_CONFIG_URL}/en/rest/V1/algolia/config/`,
    { ...fetchCache }
  );

  const data = await result.json();

  if (data?.length) {
    return data[0] as AlgoliaConfig;
  }

  return undefined;
}

// Note: This is a temporary workaround for getting products.
// Normally, this should be done through our REST API.
export async function getAlgoliaProduct(
  sku: string,
  indexName?: string
): Promise<AlgoliaProduct | undefined> {
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.fiveMinutes_300,
  });
  const result = await fetch(
    `https://${
      process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
    }-dsn.algolia.net/1/indexes/${
      indexName || process.env.NEXT_PUBLIC_ALGOLIA_DEFAULT_INDEX
    }/query`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Algolia-API-Key': process.env
          .NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string,
        'X-Algolia-Application-Id': process.env
          .NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      },
      body: JSON.stringify({
        filters: `sku:${sku}`,
      }),
      ...fetchCache,
    }
  );

  const data = await result.json();

  if (data?.hits?.length) {
    return data.hits[0] as AlgoliaProduct;
  }

  return undefined;
}

export async function getAlgoliaProducts(
  query: string,
  indexName?: string
): Promise<AlgoliaProduct[] | undefined> {
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.fiveMinutes_300,
  });
  const result = await fetch(
    `https://${
      process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
    }-dsn.algolia.net/1/indexes/${
      indexName || process.env.NEXT_PUBLIC_ALGOLIA_DEFAULT_INDEX
    }/query`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Algolia-API-Key': process.env
          .NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string,
        'X-Algolia-Application-Id': process.env
          .NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      },
      body: JSON.stringify({
        query,
      }),
      ...fetchCache,
    }
  );

  const data = await result.json();

  if (data?.hits?.length) {
    return data.hits as AlgoliaProduct[];
  }

  return undefined;
}

export async function getAlgoliaRecommendations(
  indexName: string,
  objectID: string
): Promise<AlgoliaRecommendationHit[] | undefined> {
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.fiveMinutes_300,
  });
  const result = await fetch(
    `https://${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/*/recommendations`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Algolia-API-Key': process.env
          .NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string,
        'X-Algolia-Application-Id': process.env
          .NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      },
      body: JSON.stringify({
        requests: [
          {
            indexName,
            model: 'related-products',
            objectID,
            threshold: 0,
          },
        ],
      }),
      ...fetchCache,
    }
  );

  const data = await result.json();

  if (data?.results?.length) {
    return data.results[0].hits;
  }

  return undefined;
}
