import { IProductRating } from 'types/cms/product-carousel';
import { IReviewPayload, ProductReviewFilter } from 'types/product-review';
import { fetchCacheConverter } from 'utils/cloudflare';
import { getBrowserFootprint } from 'utils/fingerprint';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { REVALIDATE } from 'lib/constants-cache';

export enum SortOrder {
  ASC = 'Rating:asc',
  DESC = 'Rating:desc',
}
export async function getProductReviews<T>(
  productId: string,
  filters: ProductReviewFilter[],
  offset: number = 0,
  sort = SortOrder.DESC // eslint-disable-line @typescript-eslint/no-unused-vars
): Promise<T> {
  let filterQuery = filterBuilder(filters);
  filterQuery = filterQuery != '' ? '&' + filterQuery : filterQuery;
  const off =
    (process.env.NEXT_PUBLIC_BAZAAR_REVIEW_PAGE_COUNT
      ? +process.env.NEXT_PUBLIC_BAZAAR_REVIEW_PAGE_COUNT
      : 0) * offset;
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.thirtyMinutes_1800,
  });
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_BAZAAR_API_URL}/data/reviews.json?apiversion=5.4&passkey=${process.env.NEXT_PUBLIC_BAZAAR_PASS_KEY}&Filter=ProductId:${productId}${filterQuery}&Limit=${process.env.NEXT_PUBLIC_BAZAAR_REVIEW_PAGE_COUNT}&Offset=${off}&Include=Products&Stats=Reviews&filter=IsRatingsOnly:false`,
    { ...fetchCache }
  );

  const data: T = await result.json();
  return data;
}

export async function getProductStatistics<T>(productId: string): Promise<T> {
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.thirtyMinutes_1800,
  });
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_BAZAAR_API_URL}/data/reviews.json?apiversion=5.4&passkey=${process.env.NEXT_PUBLIC_BAZAAR_PASS_KEY}&Filter=ProductId:${productId}&Limit=${process.env.NEXT_PUBLIC_BAZAAR_REVIEW_PAGE_COUNT}&Offset=0&Include=Products&Stats=Reviews`,
    { ...fetchCache }
  );

  const data: T = await result.json();
  return data;
}

export async function uploadPhoto(formData: FormData) {
  const url = `${process.env.NEXT_PUBLIC_BAZAAR_API_URL}/data/uploadphoto.json?apiversion=5.4&passkey=${process.env.NEXT_PUBLIC_BAZAAR_PASS_KEY}&ContentType=review`;
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const data = await response.json();
  return data;
}

export async function getProductsRatings(
  ids: string,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const url = `${process.env.NEXT_PUBLIC_BAZAAR_API_URL}/data/statistics.json?apiversion=5.4&passkey=${process.env.NEXT_PUBLIC_BAZAAR_PASS_KEY}&stats=Reviews&filter=ContentLocale:${language}_${region},ar*,en*&filter=ProductId:${ids}`;
  const fetchCache = fetchCacheConverter({
    cache: true,
    revalidate: REVALIDATE.thirtyMinutes_1800,
  });
  const result = await fetch(url, { ...fetchCache });
  const data: any = await result.json();
  const formatted = data?.Results.map((d: any) => {
    const {
      ProductId,
      ReviewStatistics: { AverageOverallRating, TotalReviewCount },
    } = d.ProductStatistics;
    return {
      id: ProductId,
      avgRating: AverageOverallRating,
      total: TotalReviewCount,
    };
  });
  // convert into a hash table data structure to keep lookup at constant time O(1);
  const hashMap: { [x: string]: IProductRating } = {};
  formatted.forEach(
    (review: any) =>
      (hashMap[review.id] = {
        avgRating: review.avgRating,
        total: review.total,
      })
  );
  return hashMap;
}

export async function addProductReview<T>(
  productId: string,
  payload: IReviewPayload
) {
  const fp = await getBrowserFootprint();
  const modifiedPayload = { ...payload, fp };
  const formBody = Object.keys(modifiedPayload)
    .map(
      (key) =>
        encodeURIComponent(key) +
        '=' +
        encodeURIComponent((modifiedPayload as any)[key])
    )
    .join('&');
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_BAZAAR_API_URL}/data/submitreview.json?apiversion=5.4&passkey=${process.env.NEXT_PUBLIC_BAZAAR_PASS_KEY}&productId=${productId}`,
    {
      method: 'POST',
      body: formBody,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

  const data: T = await result.json();
  return data;
}

/**
 *
 * @param filters An array of filters to be parsed and convcerted into a string equivalent
 * @returns returns a string equivalent of the passed in array i.e return value can be Filter=Rating:eq:5,1 for an array like [{filterLabel: 'Filter=Rating:eq', value: 5}, {filterLabel: 'Filter=Rating:eq', value: 1}]
 */
function filterBuilder(filters: ProductReviewFilter[]) {
  if (filters.length <= 0) return '';
  const obj: { [x: string]: Array<string | number> } = {};
  filters.forEach((filter) => {
    /* eslint-disable */
    if (obj.hasOwnProperty(filter.filterLabel)) return;
    obj[filter.filterLabel] = [];
  });

  filters.forEach((filter) => obj[filter.filterLabel]?.push(filter.value));

  let filterString = '';
  Object.keys(obj).forEach((key) => {
    if (filterString == '') {
      filterString = key + ':' + obj[key]?.join(',');
    } else {
      filterString += '&' + key + ':' + obj[key]?.join(',');
    }
  });

  return filterString;
}
