'use client';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { CSSProperties, useRef } from 'react';

type CustomImageProps = {
  srcset?: {
    mobileImage?: string;
    desktopImage?: string;
    mobileImageWidth?: number;
    desktopImageWidth?: number;
    mobileImageHeight?: number;
    desktopImageHeight?: number;
  };
  fill?: boolean;
  fetchPriority?: 'auto' | 'high' | 'low';
  src?: string;
  alt?: string;
  className?: string;
  style?: CSSProperties;
  id?: string;
  width?: number;
  height?: number;
  decoding?: 'async' | 'sync' | 'auto';
};

export const Media = (props: CustomImageProps) => {
  // Tailwind deafult breakpint for lg:1024px
  const windowIsMobileSize = useMediaQuery(mobileSize);
  const src =
    props.srcset?.mobileImage || props.srcset?.desktopImage || props.src;
  const imgRef = useRef<any>(null);
  return (
    src && (
      <picture>
        {props.srcset?.mobileImage && props.srcset?.desktopImage && (
          <source
            media='(max-width:1023px)'
            srcSet={props.srcset.mobileImage}
          />
        )}
        {props.srcset?.mobileImage && props.srcset?.desktopImage && (
          <source
            media='(min-width:1024px)'
            srcSet={props.srcset.desktopImage}
          />
        )}
        <img
          ref={imgRef}
          style={{
            ...(props.style || {}),
            ...(props.fill
              ? {
                  position: 'absolute',
                  inset: 0,
                  height: '100%',
                  width: '100%',
                  color: 'transparent',
                }
              : {}),
            aspectRatio:
              props.srcset?.mobileImage || props.srcset?.desktopImage
                ? `${
                    windowIsMobileSize
                      ? props.srcset?.mobileImageWidth ||
                        props.srcset?.desktopImageWidth
                      : props.srcset?.desktopImageWidth ||
                        props.srcset?.mobileImageWidth
                  }/${
                    windowIsMobileSize
                      ? props.srcset?.mobileImageHeight ||
                        props.srcset?.desktopImageHeight
                      : props.srcset?.desktopImageHeight ||
                        props.srcset?.mobileImageHeight
                  }`
                : 'auto',
          }}
          src={src}
          decoding={props.decoding || 'sync'}
          fetchPriority={props.fetchPriority || undefined}
          className={`${props.className}`}
          alt={props.alt || ''}
        />
      </picture>
    )
  );
};
