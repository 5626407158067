export function disableGlobalConfig({
  settings,
}: {
  settings: {
    DEV: {
      enabled: boolean;
      registered_only?: boolean;
    };
    STAGING: {
      enabled: boolean;
      registered_only?: boolean;
    };
    PRODUCTION: {
      enabled: boolean;
      registered_only?: boolean;
    };
  };
}) {
  let selectedSettings;
  const environment = process?.env?.NODE_ENV as string;

  switch (environment) {
    case 'development':
      selectedSettings = settings.DEV;
      break;
    case 'staging':
      selectedSettings = settings.STAGING;
      break;
    case 'production':
      selectedSettings = settings.PRODUCTION;
      break;
    default:
      selectedSettings = settings.PRODUCTION;
  }

  if (
    !selectedSettings.enabled ||
    selectedSettings?.registered_only === false
  ) {
    return false;
  }

  return true;
}
