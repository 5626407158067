import { getCmsCollection } from 'lib/nahdi-middleware';
import type {
  AlgoliaConfig,
  AlgoliaFacet,
  AlgoliaSortableAttribute,
} from 'types/algolia';
import { PlpResponseRootMdType } from 'types/cms/nahdi-middleware';
import { AlgoliaPlpProductFiltersMdType } from 'types/cms/nahdi-middleware/algolia';
import { LanguageCodesType, RegionCodesType } from 'utils';

export async function getAlgoliaConfig(
  language: LanguageCodesType,
  region: RegionCodesType,
  query?: string | any
): Promise<AlgoliaConfig | undefined> {
  const data: PlpResponseRootMdType<AlgoliaPlpProductFiltersMdType> =
    (await getCmsCollection(
      'fetch-landing-page',
      `&q=${query?.replace(/ /g, '-')}`,
      language,
      region
    )) as PlpResponseRootMdType<AlgoliaPlpProductFiltersMdType>;

  if (data?.template?.length) {
    const productFilters = data.template.find(
      (item) => item?.id === 'plp-product-filters'
    )?.components;

    const sortFilters = data.template.find(
      (item) => item?.id === 'plp-sort-by-filter'
    )?.component_properties?.widget;

    const facets: AlgoliaFacet[] = [];
    const sortableAttributes: AlgoliaSortableAttribute[] = [];

    if (productFilters) {
      productFilters?.forEach((filter) => {
        if (filter?.reference_id) {
          facets.push({
            label: filter?.label,
            attribute: filter?.reference_id,
            type: filter?.filter_type || 'SINGLE_SELECT',
          });
        }
      });
      if (sortFilters) {
        sortFilters.forEach((sort) => {
          if (sort?.sort_by_option) {
            sort?.sort_by_option?.map((option) => {
              sortableAttributes.push({
                label: option?.label?.[language] || '',
                attribute: option?.action,
                sort: option?.action,
                name: option?.index?.[region]?.[language] || 'not found index',
              });
            });
          }
        });
      }
    }
    return {
      sortableAttributes,
      facets,
      moduleType: data?.module_type,
    };
  }

  return undefined;
}
