'use client';

import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { getProductsRatings } from 'lib/bazaarvoice';
import { getProductsTile } from 'lib/nahdi-middleware';

import ProductCarouselItem from 'components/cms/banners/product-carousel-banner/product-carousel-item';
import BaseSwiper from 'components/swiper/base-swiper';
import BannerLoadingSkeleton from 'components/banner-loading-skeleton';

import clsx from 'clsx';
import { ProductItemType } from 'types/cms/product-carousel';
import ProductItem from 'components/product-item';
import { AlgoliaProduct } from 'types/product';

import { getProductsByProductIds } from 'lib/api';
import { useAppSelector } from 'redux/hooks';
import { disableGlobalConfig } from 'utils/disable-global-config';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { COOKIE_ZONE_ID } from 'utils/constant-cookies';

interface Rating {
  avgRating: number | null;
  total: number | null;
}

export default function ProductCarousel({
  products,
  swiper,
  componentIndex,
  fadeLast,
  algoliaType,
  duaLlColumn,
  customSliderPerView,
  availableOnly,
  language,
  region,
  storeZoneId,
  isWishlist,
}: {
  products: ProductItemType[] | AlgoliaProduct[];
  swiper?: boolean;
  componentIndex: number;
  fadeLast?: boolean | undefined;
  algoliaType?: boolean;
  duaLlColumn?: boolean;
  customSliderPerView?: number;
  availableOnly?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
  storeZoneId?: string;
  isWishlist?: boolean;
}) {
  const [cookies] = useCookies([COOKIE_ZONE_ID]);
  const zoneId = storeZoneId ? storeZoneId : cookies?.zoneId || '';
  const { deliveryMode } = useAppSelector((state) => state.customerReducer);
  const [dynamicProducts, setDynamicProducts] = useState(products);
  const [promoBadgeMessage, setPromoBadgeMessage] = useState();
  const [alternativeProducts, setAlternativeProducts] = useState();
  const [rating, setRating] = useState<Record<any, Rating | undefined>>({});
  const [outStock, setOutStock] = useState<Record<string, any | undefined>>({});
  const [shipmentTypes, setShipmentTypes] = useState<
    Record<string, any | undefined>
  >({});
  const [hasBundleOffer, setHasBundleOffer] = useState(false) as any;
  const isStorePickup = deliveryMode?.mode === 'pickup';
  const { appSettings } = useAppSelector((state) => state.cmsReducer);
  const maxQuantityLimit =
    appSettings?.global_components?.basket?.max_qty_per_sku;
  const disableConfigEnabled = disableGlobalConfig({
    settings: appSettings?.functionalityConfig?.bazaarVoiceSettings,
  });

  useEffect(() => {
    const fetchProductData = async () => {
      if (algoliaType) {
        const productIds = products?.map((product: any) =>
          algoliaType ? product.sku : product.sku_id
        );

        const productsData = (await getProductsTile(
          productIds.join(','),
          zoneId,
          language,
          region
        )) as any;

        const badgesMap = productsData.reduce((acc: any, product: any) => {
          acc[product.product_sku] = product.promo_badge;
          return acc;
        }, {});

        const stockMap = productsData.reduce((acc: any, product: any) => {
          acc[product.product_sku] = product.in_stock;
          return acc;
        }, {});

        const alternativeProductMap = productsData.reduce(
          (acc: any, product: any) => {
            acc[product.product_sku] = product.alternative_products;
            return acc;
          },
          {}
        );

        const hasBundleOfferMap = productsData.reduce(
          (acc: any, product: any) => {
            acc[product.product_sku] = product.has_bundle_offer;
            return acc;
          },
          {}
        );

        const shipmentTypes = productsData.reduce((acc: any, product: any) => {
          acc[product.product_sku] = product.shipment_type;
          return acc;
        }, {});

        setShipmentTypes(shipmentTypes);
        setPromoBadgeMessage(badgesMap);
        setAlternativeProducts(alternativeProductMap);
        setOutStock(stockMap);
        setHasBundleOffer(hasBundleOfferMap);
      }
    };

    const fetchRatingData = async () => {
      const productIds = products?.map((product: any) =>
        algoliaType ? product.sku : product.sku_id
      );

      const ratingsData = disableConfigEnabled
        ? await getProductsRatings(productIds as any, language, region)
        : '';

      const ratingsMap = productIds.reduce((acc: any, id: any) => {
        acc[id] = ratingsData ? ratingsData[id] : {};
        return acc;
      }, {});

      setRating(ratingsData ? ratingsMap : {});
    };

    if (products?.length > 0) {
      fetchProductData();
      fetchRatingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneId, products, disableConfigEnabled]);

  useEffect(() => {
    const fetchProductDataZoneId = async () => {
      if (!algoliaType) {
        const data = await getProductsByProductIds(
          products?.map((product: ProductItemType) => product.sku_id).join(','),
          language,
          region,
          zoneId,
          availableOnly
        );

        const updatedProducts = products?.map((product) => {
          const matchingProduct = data.find((p) => {
            if ('sku_id' in product) {
              return p.sku_id === product.sku_id;
            }
            return false;
          });

          if (matchingProduct && 'attributes' in product) {
            return {
              ...product,
              attributes: {
                ...product.attributes,
                calculated_relative_url:
                  matchingProduct.attributes.calculated_relative_url,
              },
            };
          }

          return product;
        });

        setDynamicProducts(updatedProducts as ProductItemType[]);
      }
    };
    fetchProductDataZoneId();
  }, [algoliaType, availableOnly, language, products, region, zoneId]);

  if (!products || !Array.isArray(products) || products.length === 0)
    return <BannerLoadingSkeleton />;

  const productContent = (product: any) => {
    const id = algoliaType ? product.sku : product.sku_id;
    if (algoliaType) {
      return (
        <ProductItem
          product={product}
          zoneId={zoneId}
          promoBadgeMessage={promoBadgeMessage ? promoBadgeMessage[id] : ''}
          alternativeProducts={
            alternativeProducts ? alternativeProducts[id] : []
          }
          rates={{
            avgRating: disableConfigEnabled ? rating[id]?.avgRating || 0 : 0,
            totalRatings: disableConfigEnabled ? rating[id]?.total || 0 : 0,
          }}
          inStock={Object.keys(outStock).length === 0 ? true : outStock[id]}
          isStorePickup={storeZoneId ? false : isStorePickup}
          dualColumn={duaLlColumn}
          hasBundleOffer={hasBundleOffer[id]}
          shipmentType={shipmentTypes[id]}
          language={language}
          region={region}
        />
      );
    } else {
      return (
        <ProductCarouselItem
          product={product}
          zoneId={zoneId}
          promoBadgeMessage={product?.attributes?.promo_badge}
          alternativeProducts={product?.attributes?.alternative_product}
          rates={{
            avgRating: disableConfigEnabled ? rating[id]?.avgRating || 0 : 0,
            totalRatings: disableConfigEnabled ? rating[id]?.total || 0 : 0,
          }}
          inStock={product.in_stock ? product.in_stock : 0}
          isStorePickup={storeZoneId ? false : isStorePickup}
          maxQuantityLimit={maxQuantityLimit}
          language={language}
          region={region}
          isWishlist={isWishlist}
        />
      );
    }
  };

  if (swiper) {
    return (
      <div className='relative'>
        <BaseSwiper
          swiperProps={{
            navigation: {
              nextEl: `.custom-${componentIndex}-carousel-right-button`,
              prevEl: `.custom-${componentIndex}-carousel-left-button`,
              disabledClass: 'swiper-button-disabled',
            },
            slidesPerView: 2.4,
            spaceBetween: 8,
            breakpoints: {
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: customSliderPerView ? customSliderPerView : 5.4,
              },
            },
            fadelast: fadeLast ? 'show' : 'hide',
            fadeLastBreakpoints: ['lg'],
          }}
        >
          {availableOnly
            ? dynamicProducts
                ?.filter((product) => product.in_stock)
                .map((product, index: number) => (
                  <div key={`customizr-products-${index}`} className='h-full'>
                    {productContent(product)}
                  </div>
                ))
            : dynamicProducts?.map((product, index: number) => (
                <div key={`customizr-products-${index}`} className='h-full'>
                  {productContent(product)}
                </div>
              ))}
        </BaseSwiper>

        {products && products?.length > (customSliderPerView ?? 5) && (
          <>
            <button
              type='button'
              className={clsx(
                `custom-${componentIndex}-carousel-left-button swiper-button-prev custom-swiper-button-prev`
              )}
            ></button>
            <button
              type='button'
              className={clsx(
                `custom-${componentIndex}-carousel-right-button swiper-button-next custom-swiper-button-next`
              )}
            ></button>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {dynamicProducts?.map((product, index) => {
        return (
          <div key={`customize-products-${index}`} className='h-full'>
            {productContent(product)}
          </div>
        );
      })}
    </>
  );
}
