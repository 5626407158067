import { StarIcon } from '@heroicons/react/24/solid';
import ProductCartButton from 'components/buttons/product-cart-button';
import { Link } from 'components/link';
import { DiscountFilledIcon } from 'components/icons';
import { ExpressIcon } from 'components/icons/express';
import { CircleCloseIcon, PlusIcon } from 'components/icon';
import { ProductItemType } from 'types/cms/product-carousel';
import FavoriteButton from './favorite-button';
import ResponsiveImage from 'components/responsive-image';
import { getPdpUrl } from 'utils';
import { useAppDispatch } from 'redux/hooks';
//import clsx from 'clsx';
import {
  setBundleModal,
  setBundleProductInfo,
} from 'redux/features/bundle-products';
import clsx from 'clsx';
import { useTranslation } from 'app/i18n/client';
import { setPresceriptionModal } from 'redux/features/presceription-modal';
import { ChooseAlternativeButton } from 'components/buttons/choose-alternative-button';
import { GlobalIcon } from 'components/icons/global-icon';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { gtmSetSelectItem } from 'lib/gtm';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { Media } from 'components/media';

export default function ProductCarouselItem({
  promoBadgeMessage,
  alternativeProducts,
  product,
  inStock,
  zoneId,
  rates,
  isStorePickup,
  maxQuantityLimit,
  language,
  region,
  isWishlist,
}: {
  promoBadgeMessage: string;
  alternativeProducts: [];
  product: ProductItemType;
  isOnWishlist?: boolean;
  inStock: boolean | undefined;
  zoneId: string | null;
  rates: {
    avgRating: number;
    totalRatings: number;
  };
  isStorePickup: boolean;
  maxQuantityLimit: number;
  language: LanguageCodesType;
  region: RegionCodesType;
  isWishlist?: boolean;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'product-detail-page'
  );

  const appDispatch = useAppDispatch();

  if (!product.attributes) return <div></div>;
  if (!product || !product?.product_id) return;
  const { max_orderable_quantity, min_orderable_quantity } = product.attributes;
  const isPrescription =
    product.attributes.rx_items || Number(product.attributes.rx_items) > 0;
  const hasBundleOffer = product.attributes?.bundle_skus?.length > 0;

  const isGlobalProduct =
    (isStorePickup && product.attributes.global_filter) ||
    product.shipment_type === 'global';

  const deliverType = () => {
    if (
      (isStorePickup && !product.attributes.global_filter) ||
      product.shipment_type === 'express'
    ) {
      return (
        <div
          className='flex h-[21px] items-center space-x-1 rounded-3xl bg-red px-2 py-1 rtl:space-x-reverse'
          style={{ backgroundColor: 'rgba(65, 193, 138, 1)' }}
        >
          <ExpressIcon className='h-[12px] w-[15.692px] text-white' />
          <span className='text-[11px] font-bold text-white'>Express</span>
        </div>
      );
    } else if (isGlobalProduct)
      return <GlobalIcon className='h-[21px] text-white' />;
  };

  function bundleProductHandle() {
    appDispatch(setBundleModal(true));
    appDispatch(
      setBundleProductInfo({
        id: product.sku_id,
        rates: rates,
      })
    );
  }

  const buttonCondition = () => {
    if (isPrescription) {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <button
            onClick={() => appDispatch(setPresceriptionModal(true))}
            className={clsx(
              'relative h-8 w-8 rounded-full bg-blue text-white',
              'flex items-center justify-center shadow-2xl transition-width duration-200',
              'group overflow-hidden'
            )}
          >
            <PlusIcon
              className={
                'has-color absolute h-4 w-4 fill-white ltr:right-2 rtl:left-2'
              }
            />
          </button>
        </div>
      );
    } else if (
      hasBundleOffer &&
      ((zoneId && inStock) || isStorePickup) &&
      !isPrescription
    ) {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <button
            onClick={() => bundleProductHandle()}
            className={clsx(
              'relative h-8 w-8 rounded-full bg-blue text-white',
              'flex items-center justify-center shadow-2xl transition-width duration-200',
              'group overflow-hidden'
            )}
          >
            <PlusIcon
              className={
                'has-color absolute h-4 w-4 fill-white ltr:right-2 rtl:left-2'
              }
            />
          </button>
        </div>
      );
    } else if (isStorePickup || (zoneId && inStock && !isPrescription)) {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <ProductCartButton
            product={{
              sku: product.sku_id,
              isGlobalProduct,
            }}
            maximumQuantity={
              maxQuantityLimit
                ? max_orderable_quantity > maxQuantityLimit ||
                  max_orderable_quantity === 0
                  ? maxQuantityLimit
                  : max_orderable_quantity
                : max_orderable_quantity
            }
            minimumQuantity={min_orderable_quantity}
            language={language}
            region={region}
          />
        </div>
      );
    } else if (zoneId && !inStock && !isStorePickup) {
      if (alternativeProducts?.length > 0) {
        return (
          <div className='absolute bottom-0 left-0 right-0'>
            <ChooseAlternativeButton
              alternativeProducts={alternativeProducts}
              language={language}
              region={region}
            />
          </div>
        );
      }
    } else {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <ProductCartButton
            product={{
              sku: product.sku_id,
              isGlobalProduct,
            }}
            maximumQuantity={max_orderable_quantity}
            minimumQuantity={min_orderable_quantity}
            language={language}
            region={region}
          />
        </div>
      );
    }
  };

  return (
    <div
      className={
        'relative flex h-full flex-col rounded-lg bg-white p-2 shadow-sm'
      }
    >
      <div className='relative'>
        {hasBundleOffer ? (
          <Media
            src='/assets/images/product_tags/bundle_offer.png'
            alt={'bundle product'}
            className='h-[25px] w-[70px]'
          />
        ) : product.attributes?.badge_image_url ? (
          <Media
            src={product.attributes?.badge_image_url}
            alt={product.attributes?.name}
            className='rounded-lg object-cover'
          />
        ) : (
          <div className='h-[25px]'></div>
        )}
        <Link
          href={getPdpUrl(
            product.sku_id,
            product.attributes?.calculated_relative_url,
            language,
            region
          )}
        >
          <ResponsiveImage
            imageAlt={product.attributes.name}
            productImage={product?.attributes?.media_gallery?.[0]?.types}
            imageClassName='swiper-lazy mx-auto h-[142px] w-[160px] lg:h-[210px] lg:w-[200px]'
          />
        </Link>
        {typeof inStock === 'undefined' ? (
          <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
            <div
              className={clsx(
                'relative flex h-8 w-8 animate-pulse items-center justify-center',
                'overflow-hidden rounded-full bg-blue text-white shadow-addButton'
              )}
            ></div>
          </div>
        ) : (
          buttonCondition()
        )}

        <FavoriteButton
          productId={product.sku_id}
          product={product}
          language={language}
          region={region}
        />
      </div>
      <Link
        href={getPdpUrl(
          product.sku_id,
          product.attributes?.calculated_relative_url,
          language,
          region
        )}
        className='mt-2 flex flex-1 flex-col'
      >
        <div
          className='flex flex-col items-start space-y-2'
          onClick={() => gtmSetSelectItem(product, language, region)}
        >
          {promoBadgeMessage && (
            <div className='flex items-center space-x-1 rounded-3xl bg-red py-1 pl-1 pr-2 ltr:mr-2 rtl:ml-2 rtl:space-x-reverse'>
              <DiscountFilledIcon className='h-[13px] w-[13px] text-white' />
              <span className='text-[11px] font-bold text-white'>
                {promoBadgeMessage}
              </span>
            </div>
          )}

          <span
            className={`${
              !isWishlist ? 'line-clamp-2' : ''
            } text-custom-sm text-gray-dark`}
          >
            {product.attributes.name}
          </span>
          <div
            className={`${
              isWishlist ? 'items-center' : 'max-lg:flex-col lg:items-center'
            } flex space-x-1 rtl:space-x-reverse`}
          >
            {product?.attributes?.price ==
            product?.attributes?.price_after_discount ? (
              <>
                <span className='text-custom-base font-semibold text-gray-dark'>
                  {product?.attributes?.price} {product.attributes.currency}
                </span>
                <span className='h-4 text-custom-sm'></span>
              </>
            ) : (
              <>
                <span className='text-custom-base font-semibold text-red'>
                  {product?.attributes?.price_after_discount}{' '}
                  {product.attributes.currency}
                </span>
                <span className='text-custom-sm font-semibold text-gray-dark line-through'>
                  {product?.attributes?.price} {product.attributes.currency}
                </span>
              </>
            )}
          </div>
          {isPrescription && (
            <div className='rounded-xl bg-orange/20 px-2 py-1 text-[9px] font-semibold text-orange lg:text-custom-xs'>
              {t('presceripion_required')}
            </div>
          )}
        </div>
        <div
          className={`${
            isWishlist ? 'space-x-2 rtl:space-x-reverse' : 'justify-between'
          } mt-auto flex items-center pt-2`}
        >
          {typeof inStock !== 'undefined' &&
            (zoneId && !inStock && !isStorePickup ? (
              <div
                key='sold-out-tag'
                className='me-2 flex w-max items-center gap-1 rounded-xl bg-[#d4232d10] px-2 py-1 text-custom-xs font-semibold text-red'
              >
                <CircleCloseIcon />
                {t('not_available')}
              </div>
            ) : (
              deliverType()
            ))}

          {rates?.avgRating?.toFixed(1) &&
            Number(rates?.avgRating?.toFixed(1)) > 0 && (
              <div className='flex items-center space-x-1 rtl:space-x-reverse'>
                <StarIcon className='h-4 w-4 text-primary-yellow' />
                <div className='flex items-center space-x-1 text-custom-xs font-bold text-gray rtl:space-x-reverse'>
                  <span>{rates.avgRating.toFixed(1)}</span>
                  <span
                    className={`${isWishlist ? 'block' : 'hidden sm:block'}`}
                  >
                    ({rates?.totalRatings})
                  </span>
                </div>
              </div>
            )}
        </div>
      </Link>
    </div>
  );
}
