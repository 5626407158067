'use client';
import React, { useEffect, useState } from 'react';
import { ProductCarouselItemImageTypes } from 'types/cms/product-carousel';
import { convertToAbsoluteURL } from 'utils';
import { mediumSize, mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { Media } from './media';

function ResponsiveImage({
  productImage,
  imageAlt = '',
  imageClassName = '',
}: {
  productImage?: ProductCarouselItemImageTypes;
  imageClassName: string;
  imageAlt: string;
}) {
  const windowIsMediumSize = useMediaQuery(mediumSize);
  const windowIsMobileSize = useMediaQuery(mobileSize);
  const [ImageSrc, setImageSrc] = useState<string | null | undefined>(null);

  useEffect(() => {
    const handleImageSrc = () => {
      if (!productImage) {
        return null;
      }
      if (windowIsMediumSize) {
        return productImage?.medium;
      }
      if (windowIsMobileSize) {
        return productImage?.small;
      } else {
        return productImage.large;
      }
    };
    setImageSrc(handleImageSrc());
  }, [productImage, windowIsMediumSize, windowIsMobileSize]);

  return ImageSrc ? (
    <Media
      src={convertToAbsoluteURL(ImageSrc)}
      alt={imageAlt}
      width={200}
      height={200}
      className={imageClassName}
    />
  ) : (
    <div className={imageClassName}></div>
  );
}

export default ResponsiveImage;
